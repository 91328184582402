import {LazyRoute, BoringRoute, Parse} from 'trouty';
import {safeLazyImport} from 'bigdatr-style';

const UserAdminTeamList = safeLazyImport(() => import('~/user/UserAdminTeamList'));
const UserAdminTeamUsers = safeLazyImport(() => import('~/user/UserAdminTeamUsers'));
const UserAdminTeamAccess = safeLazyImport(() => import('~/user/UserAdminTeamAccess'));
const UserAdminUserList = safeLazyImport(() => import('~/user/UserAdminUserList'));
const UserAdminUserItem = safeLazyImport(() => import('~/user/UserAdminUserItem'));
const ContentMover = safeLazyImport(() => import('~/feature/content-mover/ContentMover'));

export default {
    userAdminTeamList: LazyRoute<{status?: string; q?: string}>({
        path: '/user-admin/team',
        parse: {
            status: Parse.query.string((x) => x || 'ACTIVE'),
            q: Parse.query.string((x) => x || '')
        },
        component: UserAdminTeamList
    }),
    userAdminTeamUsers: LazyRoute<{id: string; q?: string}>({
        path: '/user-admin/team/:id/users',
        parse: {
            id: Parse.param.string((x) => x || ''),
            q: Parse.query.string((x) => x || '')
        },
        component: UserAdminTeamUsers
    }),
    userAdminTeamAccess: LazyRoute<{id: string}>({
        path: '/user-admin/team/:id/access',
        parse: {
            id: Parse.param.string((x) => x || '')
        },
        component: UserAdminTeamAccess
    }),
    userAdminUserList: BoringRoute({
        path: '/user-admin/user',
        component: UserAdminUserList
    }),
    userAdminUserItem: LazyRoute<{id: string}>({
        path: '/user-admin/user/:id',
        parse: {
            id: Parse.param.string((x) => x || '')
        },
        component: UserAdminUserItem
    }),
    contentMover: BoringRoute({
        path: '/user-admin/content-mover',
        component: ContentMover
    })
};
