import {MediaValueCsvQueryVariables} from '~/graphqlTypes';
import {MediaValueRowsQueryVariables} from '~/graphqlTypes';
import {MediaValueRowsQuery} from '~/graphqlTypes';
import {MediaValueCsvQuery} from '~/graphqlTypes';
import {EntityStrict, Meta} from '~/app/Endpoints';
import {GraphqlRequest} from 'bigdatr-style';
import {add, startOf} from 'stringdate';

const csv = EntityStrict<MediaValueCsvQueryVariables>(() => import('./MediaValueCsv.graphql'));
const rows = EntityStrict<MediaValueRowsQueryVariables>(() => import('./MediaValueRows.graphql'));

export type MediaValueApiType = {
    mediaValue: {
        rows: GraphqlRequest<MediaValueRowsQuery, MediaValueRowsQueryVariables>;
        csv: GraphqlRequest<MediaValueCsvQuery, MediaValueCsvQueryVariables>;
    };
};

// This is a bit hacky but making all date pickers exclusive
// turned out to be too much work. So this just mutates the end date to
// be one more than asked for.
function makeDateRangeExclusive(dateRangeFilter: MediaValueRowsQueryVariables['dateRangeFilter']) {
    return {
        ...dateRangeFilter,
        ...(dateRangeFilter?.absolute && {
            absolute: {
                startDate: dateRangeFilter.absolute.startDate,
                endDate: startOf('month')(add('P1M')(dateRangeFilter.absolute.endDate))
            }
        })
    };
}

export const MediaValueApi = {
    mediaValue: {
        rows: async (v: MediaValueRowsQueryVariables, meta: Meta) => {
            const variables = {...v, dateRangeFilter: makeDateRangeExclusive(v.dateRangeFilter)};
            const {
                columns = [],
                columnFilter,
                dateRangeFilter,
                pagination,
                releaseId,
                changesetId
            } = variables;
            const response = await rows(variables, meta);
            response.mediaValue.rows.id = JSON.stringify([
                columns,
                columnFilter,
                dateRangeFilter,
                pagination?.orderBy,
                releaseId,
                changesetId
            ]);
            return response;
        },
        csv: async (v: MediaValueCsvQueryVariables, meta: Meta) => {
            const variables = {...v, dateRangeFilter: makeDateRangeExclusive(v.dateRangeFilter)};
            return csv(variables, meta);
        }
    }
};
