import {
    ClassSchema,
    CreativeStatus,
    GraphqlRequest,
    InfinitePaginationSchema,
    Schemas
} from 'bigdatr-style';
import {
    ConvertNameFiltersToIdsQuery,
    ConvertNameFiltersToIdsQueryVariables,
    FiltersV2QueryVariables,
    GetEntitiesByIdQueryVariables
} from '~/graphqlTypes';
import {EntityStrict, Meta} from '~/app/Endpoints';
import {ColumnFilterV2} from '~/report/data/ReportItemDefinitionTypes';
import {AdType, Brand, Campaign, Category, Industry, MediaType, Product} from 'bigdatr-style';
import {Publication, Region, Publisher, MediaOwner} from 'bigdatr-style';
import {ArraySchema, EntitySchema, ObjectSchema} from 'react-enty';
import Release from '~/feature/Release';
import Changeset from '~/feature/Changeset';
import Segment from '~/segment/data/Segment';
import {segment} from '~/segment/data/SegmentApi';
import Location from '~/feature/Location';

const convertNameFiltersToIdsQuery = EntityStrict(
    () => import('./ConvertNameFiltersToIdsQuery.graphql')
);
const filtersV2Query = EntityStrict(() => import('./FiltersV2Query.graphql'));
const getEntitiesByIdQuery = EntityStrict(() => import('./GetEntitiesByIdQuery.graphql'));

type FilterPayload = {
    input: FiltersV2QueryVariables['input'];
    filter: ColumnFilterV2;
};

type FilterResponse = {
    id: string;
    column: string;
    pageInfo: {
        currentPage: number;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
    };
    items: FilterEntities & {location: Location[]; status: CreativeStatus[]};
};

export type FilterApiType = {
    filter: {
        convertNameFiltersToIds: GraphqlRequest<
            ConvertNameFiltersToIdsQuery,
            ConvertNameFiltersToIdsQueryVariables
        >;
        filters: GraphqlRequest<{filterV2: {filters: FilterResponse}}, FilterPayload>;
        getEntitiesById: GraphqlRequest<
            {filterV2: {getEntitiesById: FilterEntities}},
            GetEntitiesByIdQueryVariables
        >;
    };
};

const releaseSchema = new EntitySchema('release', {});
const changesetSchema = new EntitySchema('changeset', {});
const locationSchema = new EntitySchema('location', {});
const statusSchema = new EntitySchema('status', {});

releaseSchema.shape = new ClassSchema(Release, {});
changesetSchema.shape = new ClassSchema(Changeset, {});
locationSchema.shape = new ClassSchema(Location, {});
statusSchema.shape = new ClassSchema(CreativeStatus, {});

const schema = {
    adType: Schemas.adTypeList,
    brand: Schemas.brandList,
    campaign: Schemas.campaignList,
    category: Schemas.categoryList,
    industry: Schemas.industryList,
    location: new ArraySchema(locationSchema),
    mediaOwner: Schemas.mediaOwnerList,
    mediaType: Schemas.mediaTypeList,
    product: Schemas.productList,
    publication: Schemas.publicationList,
    publisher: Schemas.publisherList,
    region: Schemas.regionList,
    release: new ArraySchema(releaseSchema),
    changeset: new ArraySchema(changesetSchema),
    segment: new ArraySchema(segment),
    status: new ArraySchema(statusSchema)
};

const filtersPaginationSchema = new InfinitePaginationSchema<FilterResponse>('filters', {
    id: (x) => x.id,
    shape: new ObjectSchema({
        items: new ObjectSchema(schema)
    }),
    pageNumber: (x) => x.pageInfo.currentPage,
    flatten: (list, mostRecent) => {
        return {
            ...mostRecent,
            items: list.reduce((rr, page) => {
                const {column, items} = page;
                rr[column] ||= [];
                rr[column] = rr[column].concat(items[column]);
                return rr;
            }, {} as FilterResponse['items'])
        };
    }
});

export const FilterSchema = {
    filterV2: new ObjectSchema({
        filters: filtersPaginationSchema,
        getEntitiesById: new ObjectSchema({
            ...schema
        })
    })
};

export const FilterApi = {
    filter: {
        convertNameFiltersToIds: convertNameFiltersToIdsQuery,
        filters: async (v: FiltersV2QueryVariables, meta: Meta) => {
            const response = await filtersV2Query(v, meta);
            response.filterV2.filters.id = JSON.stringify([
                v.filter,
                v.input.column,
                v.input.pageSize,
                v.input.searchQuery
            ]);

            return response;
        },
        getEntitiesById: getEntitiesByIdQuery
    }
};

export type FilterEntities = {
    adType: AdType[];
    brand: Brand[];
    campaign: Campaign[];
    category: Category[];
    industry: Industry[];
    mediaOwner: MediaOwner[];
    mediaType: MediaType[];
    product: Product[];
    publication: Publication[];
    publisher: Publisher[];
    region: Region[];
    release: Release[];
    changeset: Changeset[];
    segment: Segment[];
};
