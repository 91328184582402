import {SharedUser, SegmentSharing, DeletedStatus} from '~/graphqlTypes';
import ShareModel from '~/affordance/sharing/ShareModel';
import {QueryStringFilters} from '~/feature/filter/types';
import {
    AdType,
    Brand,
    Campaign,
    Category,
    Industry,
    MediaOwner,
    MediaType,
    Product,
    Publisher,
    Region
} from 'bigdatr-style';

export type SegmentDefinition = {
    adType?: Array<AdType>;
    brand?: Array<Brand>;
    category?: Array<Category>;
    industry?: Array<Industry>;
    mediaOwner?: Array<MediaOwner>;
    mediaType?: Array<MediaType>;
    product?: Array<Product>;
    publisher?: Array<Publisher>;
    region?: Array<Region>;
    campaign?: Array<Campaign>;
};

export function convertSegmentToFilters(segment: SegmentDefinition): QueryStringFilters {
    const queryStringFilters: QueryStringFilters = {};

    queryStringFilters.adType = segment.adType?.map((ii) => ii.id);
    queryStringFilters.brand = segment.brand?.map((ii) => ii.id);
    queryStringFilters.category = segment.category?.map((ii) => ii.id);
    queryStringFilters.industry = segment.industry?.map((ii) => ii.id);
    queryStringFilters.mediaOwner = segment.mediaOwner?.map((ii) => ii.id);
    queryStringFilters.mediaType = segment.mediaType?.map((ii) => ii.id);
    queryStringFilters.product = segment.product?.map((ii) => ii.id);
    queryStringFilters.publisher = segment.publisher?.map((ii) => ii.id);
    queryStringFilters.region = segment.region?.map((ii) => ii.id);
    queryStringFilters.campaign = segment.campaign?.map((ii) => ii.id);

    return queryStringFilters;
}

type SegmentInput = {
    id: string;
    name: string;
    color: string;
    description: string | null;
    definitionV2?: SegmentDefinition;
    emailAlert: boolean;
    author: SharedUser;
    teamId: string;
    teamName?: string;
    sharing: SegmentSharing;
    status?: DeletedStatus;
    createdAt: string;
    updatedAt: string;
};

export default class Segment extends ShareModel {
    id: string;
    name: string;
    color: string;
    description: string | null;
    emailAlert: boolean;
    definitionV2?: SegmentDefinition;
    author: SharedUser;
    teamId: string;
    teamName: string;
    status?: DeletedStatus;
    createdAt: string;
    updatedAt: string;
    type = 'segment' as const;

    constructor(input: SegmentInput) {
        super();
        this.id = input.id;
        this.name = input.name;
        this.color = input.color;
        this.definitionV2 = input.definitionV2;
        this.description = input.description;
        this.emailAlert = input.emailAlert;
        this.author = input.author;
        this.teamId = input.teamId;
        this.teamName = input.teamName || 'Unknown team';
        this.sharing = input.sharing;
        this.status = input.status;
        this.createdAt = input.createdAt;
        this.updatedAt = input.updatedAt;
    }

    static defaultName = 'Untitled segment';

    static defaultDescription =
        'Enter a description. This can help others better understand your segment.';

    static welcomeTitle = 'Welcome to Segments!';

    static welcomeText =
        'Segments let you combine multiple filters into one, and assign custom colours.';

    static secretCreativeEmailSegmentName = 'Bigdatr Daily Creative Email';

    static to(id: string) {
        return `/segment/${id}`;
    }

    get to() {
        return Segment.to(this.id);
    }

    get label() {
        return this.name;
    }

    get deleted() {
        return this.status === 'DELETED';
    }
}
