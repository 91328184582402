import {LazyRoute, Parse} from 'trouty';
import {safeLazyImport} from 'bigdatr-style';
import type {QueryStringFilters} from '~/feature/filter/types';
import type {CreativeDisplay} from '~/feature/creative/display-toggle';
import {CreativeListOrderBy} from '~/creative/affordance/CreativeListSortBy';

const PdfReport = safeLazyImport(() => import('~/pdf/PdfReport'));
const PdfCreativeProfile = safeLazyImport(() => import('~/pdf/PdfCreativeProfile'));

export type ReportPrintConfig = {
    creativeLimit?: number;
    matrixRowLimit?: number;
    tableColumns?: string[];
};

export type CreativeProfilePrintConfig = {
    displayMode?: CreativeDisplay;
    tableColumns?: string[];
    creativeListOrderBy?: CreativeListOrderBy;
};

export type PdfCreativeProfileArgs = {
    filter: QueryStringFilters;
    appUrl: string;
    printConfig?: CreativeProfilePrintConfig;
};
export default {
    pdfReport: LazyRoute<{
        reportId: string;
        printConfig?: ReportPrintConfig;
    }>({
        path: '/pdf/report/:reportId',
        parse: {
            reportId: Parse.param.string((x) => x || ''),
            printConfig: Parse.query.JSON((x) => (x || {}) as ReportPrintConfig)
        },
        component: PdfReport
    }),
    pdfCreativeProfile: LazyRoute<PdfCreativeProfileArgs>({
        path: '/pdf/creativeProfile',
        parse: {
            filter: Parse.query.JSON((x) => (x || {}) as QueryStringFilters),
            appUrl: Parse.query.string((x) => x || '/'),
            printConfig: Parse.query.JSON((x) => (x || {}) as CreativeProfilePrintConfig)
        },
        component: PdfCreativeProfile
    })
};
