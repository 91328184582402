import {Button, Clickable, ErrorBoundary, Navigation} from 'bigdatr-style';
import {Box} from 'bigdatr-style/layout';
import React from 'react';
import {Route, Switch} from 'react-router-dom';
import CreativeItemView from '~/creative/CreativeItemView';
import useEventTracking from './useEventTracking';

export const publicRoutePaths = ['/creative/:creativeId/'];

function PublicRoutes() {
    const {registerCtaClick} = useEventTracking();

    return (
        <>
            <Navigation
                items={[
                    {
                        children: (
                            <Box onClick={() => registerCtaClick('navigationBar')}>
                                <RegisterButton to="/register" />
                            </Box>
                        )
                    },
                    {
                        to: {pathname: '/login', state: {from: window.location.pathname}},
                        children: <Button secondary children="Log in" />
                    }
                ]}
            />
            <ErrorBoundary>
                <Switch>
                    <Route path="/creative/:creativeId" component={CreativeItemView} />
                </Switch>
            </ErrorBoundary>
        </>
    );
}

const RegisterButton = (props: {children?: React.ReactNode; large?: boolean; to?: string}) => {
    const registerProps = useRegisterLinkProps();
    const {children = 'Free Trial', large} = props;
    return <Button large={large} to={props.to || registerProps.to} children={children} />;
};

const useRegisterLinkProps = (): React.ComponentProps<typeof Clickable> => {
    return {to: {pathname: '/register', state: {clearForm: true}}};
};

export default PublicRoutes;
