import React, {Suspense, useEffect, useMemo, memo} from 'react';
import {EntityProvider} from '~/app/EntityApi';
import api from '~/app/EntityApi';
import {ErrorBoundary, useStageFavicon, useViewer, Viewer} from 'bigdatr-style';
import {AnalyticsProvider} from 'bigdatr-style';
import {ViewerProvider} from 'bigdatr-style';
import {safeLazyImport} from 'bigdatr-style';
import {useAuth} from 'bigdatr-style';
import {Loader} from 'bigdatr-style';
import {Theme} from 'bigdatr-style';
import {AuthProvider} from 'bigdatr-style';
import {useHistory} from 'react-router-dom';
import {Analytics} from 'bigdatr-style';
import {Segment} from 'bigdatr-style';
import {Google} from 'bigdatr-style';
import {Sentry} from 'bigdatr-style';
import {Hubspot} from 'bigdatr-style';
import {ModalProvider} from 'bigdatr-style';
import {ChatHeadPlaceholder} from 'bigdatr-style';
import {useLocation} from 'react-router-dom';
import {RoutesProvider} from '~/app/Router';
import {BrowserRouter} from 'trouty';
import PublicRoutes, {publicRoutePaths} from './PublicRoutes';
import pdfRoutePaths from '~/pdf/data/pdfRoutePaths';
import AppLayout from './AppLayout';
import {useSettings} from './SettingsStore';
import {FeatureFlagsProvider, useLaunchDarklyClient} from 'bigdatr-style';

const AuthenticatedRoutes = safeLazyImport(() => import('~/app/AuthenticatedRoutes'));
const OnboardRoutes = safeLazyImport(() => import('~/onboard/OnboardRoutes'));

const segment = new Segment(process.env.SEGMENT_WRITE_KEY_ENTERPRISE || '');
const sentry = new Sentry({
    dsn: process.env.SENTRY_DSN ?? '',
    service: 'bigdatr-client-main',
    environment: process.env.STAGE ?? '',
    release: process.env.RELEASE_NAME ?? ''
});
const hubspot = new Hubspot();
const googleAnalytics = new Google(process.env.GA_MEASUREMENT_ID || '');
const analytics = new Analytics({segment, sentry, hubspot, googleAnalytics});

//
// Set up react-router, theme, and auth
export default function GlobalProviders() {
    const [settings] = useSettings();
    useStageFavicon();
    return (
        <BrowserRouter>
            <Theme name={settings.theme}>
                <FeatureFlagsProvider>
                    <AnalyticsProvider value={analytics}>
                        <ErrorBoundary>
                            <Suspense fallback={null}>
                                <AuthProvider
                                    publicRoutes={publicRoutePaths}
                                    pdfRoutes={pdfRoutePaths}
                                >
                                    <RoutesProvider>
                                        <EntyWithAuth />
                                    </RoutesProvider>
                                </AuthProvider>
                            </Suspense>
                        </ErrorBoundary>
                    </AnalyticsProvider>
                </FeatureFlagsProvider>
            </Theme>
        </BrowserRouter>
    );
}

function EntyWithAuth() {
    const authData = useAuth();
    // A lot of our data is tainted by the current team id being sent through headers
    // This trashes enty state if the team changes and avoids any tainted entities
    return (
        <EntityProvider key={authData.teamId} meta={authData}>
            {authData.isPublic ? <PublicRoutes /> : <WithViewerAndSegments />}
        </EntityProvider>
    );
}

function WithViewerAndSegments() {
    const auth = useAuth();
    const teamId = auth.teamId ?? 'NO_TEAM';
    const viewerMessage = api.viewer.useRequest({responseKey: teamId});
    const dailySegment = api.segmentV2.creativeEmailSegment.useRequest({key: teamId});

    const dailyEmailSegment = useMemo(() => {
        return dailySegment.response?.segmentV2.creativeEmailSegment;
    }, [teamId, dailySegment.isSuccess]);

    const viewerResponse = useMemo(() => {
        return viewerMessage.response;
    }, [
        // Memoise on
        // team id to check if user switches teams
        teamId,
        // viewerMessage.isSuccess to check for new data
        viewerMessage.isSuccess,
        // dailyEmailSegment.id to see if user completed onboarding
        dailyEmailSegment?.id
    ]);

    useEffect(() => {
        viewerMessage.request({teamId});
    }, [teamId]);

    useEffect(() => {
        if (dailySegment.isEmpty) dailySegment.request();
    }, [dailySegment.isEmpty]);

    if (dailySegment.isError) throw dailySegment.requestError;
    if (viewerMessage.isError) throw viewerMessage.requestError;
    if (viewerMessage.isSuccess && viewerResponse && dailySegment.isSuccess) {
        return (
            <WithViewer
                viewerResponse={viewerResponse}
                hasDailyEmailSegment={!!dailyEmailSegment}
            />
        );
    }
    return <Loader />;
}

function WithViewer(props: {viewerResponse: ViewerResponse; hasDailyEmailSegment: boolean}) {
    const {viewer} = props.viewerResponse;

    const ldClient = useLaunchDarklyClient();
    const {pathname} = useLocation();
    const isPdfRoute = !!pathname.match(/^\/pdf/);

    // disable live flag updates if on a pdf route. it prevents puppeteer from saving the pdf,
    // because the streaming GET endpoint never resolves. And we create the pdf only once all
    // network requests are done
    useEffect(() => {
        ldClient.setStreaming(!isPdfRoute);
    }, [isPdfRoute]);

    // Analytics tracking
    useEffect(() => {
        analytics.add('launchDarkly', ldClient);

        const release = process.env.RELEASE_NAME || '';
        const {currentTeam} = viewer;
        analytics.identify({
            id: viewer.user.id,
            name: viewer.user.name,
            firstname: viewer.user.firstname,
            email: viewer.user.username,
            hasMediaValue: currentTeam?.hasMediaValue,
            hasCreative: currentTeam?.hasAdvertisingCreative,
            teamName: currentTeam?.name,
            teamId: currentTeam?.id,
            release,
            isStaff: isStaff(viewer.user.username),
            createdAt: viewer.user.createdAt
        });
    }, [viewer.currentTeamId]);

    if (ldClient.isAnonymousContext()) return <Loader />;

    return (
        <ViewerProvider value={viewer}>
            <AuthenticatedView
                hasDailyEmailSegment={props.hasDailyEmailSegment}
                isPdfRoute={isPdfRoute}
            />
        </ViewerProvider>
    );
}
type ViewerResponse = NonNullable<(typeof api)['viewer']['_messageType']['response']>;
const AuthenticatedView = memo(function AuthenticatedViewMemo({
    hasDailyEmailSegment,
    isPdfRoute
}: {
    hasDailyEmailSegment: boolean;
    isPdfRoute: boolean;
}) {
    const {changeTeam, initialLogin, clearInitialLogin} = useAuth();
    const history = useHistory<{dontTrack: boolean}>();
    const viewer = useViewer();

    const isDev = process.env.STAGE === 'development' || process.env.STAGE === 'local';

    // Open up chat if the openChat query param is preset
    useEffect(() => {
        if (!isPdfRoute && history.location.search.indexOf('openChat=true') !== -1)
            analytics.openChatOnPageLoad();
    }, []);

    useEffect(() => {
        analytics.page();
        return history.listen((history) => {
            if (history.state?.dontTrack) return;
            analytics.page();
        });
    }, [viewer.id]);

    useEffect(() => {
        if (initialLogin) {
            // Can't use useEventTracking as this is above contexts :(
            analytics.trackEvent('register:complete');
            clearInitialLogin();
        }
    }, [viewer.currentTeamId]);

    const shouldOnboard = shouldOnboardUser(viewer, hasDailyEmailSegment);
    let routes: React.ReactNode;
    if (shouldOnboard) routes = <OnboardRoutes />;
    else routes = <AuthenticatedRoutes openChat={() => analytics.chat()} />;

    return (
        <ModalProvider>
            <AppLayout
                hideSidebar={isPdfRoute}
                showNavLinks={!shouldOnboard}
                changeTeam={changeTeam}
            >
                {routes}
            </AppLayout>
            {isDev && <ChatHeadPlaceholder />}
        </ModalProvider>
    );
});

function shouldOnboardUser(viewer: Viewer, hasDailyEmailSegment: boolean) {
    return (
        // admin teams don't need to onboard
        !viewer?.currentTeam?.isAdminOnlyTeam &&
        // team with no permsissions dont have to either
        !viewer?.currentTeam?.hasNoPermissions &&
        // users who don't have their daily email segment need to onboard
        !hasDailyEmailSegment
    );
}

function isStaff(email: string) {
    return ['@bigdatr.com', '@blueflag.com.au', '@microsourcing.ph'].some((ee) =>
        email.endsWith(ee)
    );
}
