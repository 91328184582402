import Endpoints from '~/app/Endpoints';
import updateIn from 'unmutable/updateIn';
import type {Meta} from '~/app/Endpoints';
import {EntyRequest, ResponseMeta, Team} from 'bigdatr-style';
import {TeamMember} from 'bigdatr-style';

const {EntityStrict} = Endpoints;

const teamItem = EntityStrict(() => import('~/user/data/TeamItemQuery.graphql'));
const teamUpdate = EntityStrict(() => import('~/user/data/TeamUpdateMutation.graphql'));
const teamRemove = EntityStrict(() => import('~/user/data/TeamRemoveMutation.graphql'));
const teamList = EntityStrict(() => import('~/user/data/TeamListQuery.graphql'));
const teamCreateWithInvite = EntityStrict(
    () => import('~/user/data/TeamCreateWithInviteMutation.graphql')
);
const teamMemberUpdate = EntityStrict(() => import('~/user/data/TeamMemberUpdateMutation.graphql'));
const teamMemberRemove = EntityStrict(() => import('~/user/data/TeamMemberRemoveMutation.graphql'));
const teamMemberInvite = EntityStrict(() => import('~/user/data/TeamMemberInviteMutation.graphql'));
const teamMemberAdd = EntityStrict(() => import('~/user/data/TeamMemberAddMutation.graphql'));
const teamInviteResend = EntityStrict(() => import('~/user/data/TeamInviteResendMutation.graphql'));
const teamInviteRemove = EntityStrict(() => import('~/user/data/TeamInviteRemoveMutation.graphql'));
const teamUpdateProducts = EntityStrict(
    () => import('~/user/data/TeamUpdateProductsMutation.graphql')
);
const teamMoveUserToEnterprise = EntityStrict(
    () => import('~/user/data/TeamMoveUserToEnterprise.graphql')
);

type TeamInvite = {
    username: string;
    teamId: string;
    inviterId: string;
    role: 'MEMBER' | 'ADMIN';
};

type TeamInviteErrorResponse = {
    username: string;
    error: string;
};

export default {
    teamItem,
    teamList,
    teamUpdate,
    teamUpdateProducts: async (
        vv: {id: string; products: Array<unknown>},
        mm: Meta
    ): Promise<unknown> => {
        // NOTE: them teamUpdateProducts mutation returns a new list of products.
        // We can return a psuedo team here and let enty normalize the new products in.
        const {team} = await teamUpdateProducts(vv, mm);
        return {
            team: {team: {id: vv.id, products: team.teamUpdateProducts}}
        };
    },

    teamRemove: async (vv: unknown, mm: Meta): Promise<unknown> => {
        await teamRemove(vv, mm);
        return teamList(vv, mm);
    },
    teamInviteResend,
    teamInviteRemove: async (vv: unknown, mm: Meta): Promise<unknown> => {
        await teamInviteRemove(vv, mm);
        return teamItem(vv, mm);
    },
    teamMemberRemove: async (vv: unknown, mm: Meta): Promise<unknown> => {
        await teamMemberRemove(vv, mm);
        return teamItem(vv, mm);
    },
    teamMemberUpdate,
    teamCreate: async (vv: unknown, mm: Meta): Promise<unknown> => {
        // team create returns a single team. Make it an array so it can
        // be concatenated to the main list
        const data = await teamCreateWithInvite(vv, mm);

        const modifiedData = updateIn(['team', 'teamCreateWithInvite'], (team) => [team])(data);
        return {
            ...data,
            team: {
                // Change the team to array for normalizing the team list + add item for response usage
                teamCreateWithInvite: modifiedData.team.teamCreateWithInvite,
                team: data.team.teamCreateWithInvite
            }
        };
    },
    teamMemberAdd: async (
        members: Array<{userId: string; teamId: string; role: string}>,
        meta: Meta
    ) => {
        return members.reduce(
            (pp, member) => pp.then(() => teamMemberAdd({member}, meta)),
            Promise.resolve()
        );
    },
    teamMemberInvite: async (invites: Array<TeamInvite>, meta: Meta) => {
        const teamId = invites[0].teamId;

        const errors: TeamInviteErrorResponse[] = [];
        for (const invite of invites) {
            try {
                await teamMemberInvite({invite}, meta);
            } catch (e) {
                errors.push({username: invite.username, error: e.message});
            }
        }

        const team = await teamItem({teamId}, meta);
        team.inviteErrors = errors;
        return team;
    },
    teamMoveUserToEnterprise: async (
        args: {
            newOrExisting: 'new' | 'existing';
            userId: string;
            teamName?: string;
            teamId?: string;
            role?: 'OWNER' | 'MEMBER';
        },
        meta: Meta
    ): Promise<unknown> => teamMoveUserToEnterprise(args, meta)
};

type TeamRequest<T> = EntyRequest<{team: T; responseMeta: ResponseMeta}>;
export type TeamApiType = {
    team: {
        teamCreate: TeamRequest<{teamCreateWithInvite: Team[]; team: Team}>;
        teamInviteResend: TeamRequest<{teamResendInvite: boolean}>;
        teamInviteRemove: TeamRequest<{teamInviteRemove: boolean}>;
        teamItem: TeamRequest<{team: Team}>;
        teamMemberAdd: TeamRequest<{teamAddUser: TeamMember}>;
        teamMemberInvite: TeamRequest<{teamInviteUser: any}>;
        teamMemberRemove: TeamRequest<{teamRemoveUser: boolean}>;
        teamMemberUpdate: TeamRequest<{teamUpdateUser: TeamMember}>;
        teamRemove: TeamRequest<{teamRemove: boolean}>;
        teamUpdate: TeamRequest<{teamUpdate: Team}>;
        teamUpdateProducts: TeamRequest<{teamList: Team[]}>;
        teamList: TeamRequest<{teamList: Team[]}>;
        teamMoveUserToEnterprise: TeamRequest<{team: Team}>;
    };
};
