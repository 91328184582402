import {EntitySchema, ObjectSchema, ArraySchema} from 'react-enty';
import {ClassSchema, GraphqlRequest, Schemas} from 'bigdatr-style';
import {EntityStrict} from '~/app/Endpoints';
import Segment, {SegmentDefinition} from './Segment';
import {
    SegmentV2MutationSegmentCreateArgs,
    SegmentV2MutationSegmentUpdateArgs,
    SegmentV2MutationSegmentRemoveArgs,
    SegmentV2QuerySegmentArgs,
    SegmentV2QuerySegmentListArgs,
    SegmentV2MutationCreativeEmailSegmentUpdateArgs
} from '~/graphqlTypes';

const SegmentQuery = EntityStrict(() => import('./SegmentQuery.graphql'));
const SegmentListQuery = EntityStrict(() => import('./SegmentListQuery.graphql'));
const SegmentUpdateMutation = EntityStrict(() => import('./SegmentUpdateMutation.graphql'));
const SegmentCreateMutation = EntityStrict<SegmentV2MutationSegmentCreateArgs>(
    () => import('./SegmentCreateMutation.graphql')
);
const SegmentRemoveMutation = EntityStrict(() => import(`./SegmentRemoveMutation.graphql`));
const CreativeEmailSegment = EntityStrict(() => import(`./CreativeEmailSegment.graphql`));
const CreativeEmailSegmentUpdate = EntityStrict<SegmentV2MutationCreativeEmailSegmentUpdateArgs>(
    () => import(`./CreativeEmailSegmentUpdate.graphql`)
);

export const segment = new EntitySchema('segment');
const creativeEmailSegment = new EntitySchema('segment', {
    id: () => 'creativeEmailSegment'
});

const segmentList = new EntitySchema('segmentList', {
    shape: new ObjectSchema({
        data: new ArraySchema(segment)
    }),
    id: () => 'teamSegments'
});

function getSegmentShape() {
    return new ClassSchema(Segment, {
        definitionV2: new ObjectSchema({
            adType: Schemas.adTypeList,
            brand: Schemas.brandList,
            industry: Schemas.industryList,
            category: Schemas.categoryList,
            campaign: Schemas.campaignList,
            publication: Schemas.publicationList,
            publisher: Schemas.publisherList,
            region: Schemas.regionList,
            product: Schemas.productList,
            mediaOwner: Schemas.mediaOwnerList,
            mediaType: Schemas.mediaTypeList
        })
    });
}
segment.shape = getSegmentShape();
creativeEmailSegment.shape = getSegmentShape();

export const SegmentSchema = {
    segmentV2: new ObjectSchema({
        segment,
        segmentCreate: segment,
        segmentUpdate: segment,
        segmentList,
        creativeEmailSegment: creativeEmailSegment,
        creativeEmailSegmentCreate: creativeEmailSegment,
        creativeEmailSegmentUpdate: creativeEmailSegment,
        followBrand: creativeEmailSegment
    })
};

export type SegmentApiType = {
    segmentV2: {
        segmentCreate: GraphqlRequest<
            {segmentV2: {segmentCreate: Segment}},
            SegmentV2MutationSegmentCreateArgs
        >;
        segmentUpdate: GraphqlRequest<
            {segmentV2: {segmentUpdate: Segment}},
            SegmentV2MutationSegmentUpdateArgs
        >;
        segmentRemove: GraphqlRequest<
            {segmentV2: {segmentRemove: boolean}},
            SegmentV2MutationSegmentRemoveArgs
        >;
        segment: GraphqlRequest<{segmentV2: {segment: Segment}}, SegmentV2QuerySegmentArgs>;
        segmentList: GraphqlRequest<
            {segmentV2: {segmentList: {data: Array<Segment>}}},
            SegmentV2QuerySegmentListArgs
        >;
        creativeEmailSegment: GraphqlRequest<
            {segmentV2: {creativeEmailSegment: Segment | null}},
            void
        >;
        creativeEmailSegmentCreate: GraphqlRequest<
            {segmentV2: {creativeEmailSegmentCreate: Segment | null}},
            CreativeEmailSegmentInput
        >;
        creativeEmailSegmentUpdate: GraphqlRequest<
            {segmentV2: {creativeEmailSegmentUpdate: Segment | null}},
            CreativeEmailSegmentInput & {id: string}
        >;
        followBrand: GraphqlRequest<
            {segmentV2: {creativeEmailSegmentUpdate: Segment | null}},
            FollowBrandInput
        >;
    };
};

export const SegmentApi = {
    segmentV2: {
        segmentCreate: SegmentCreateMutation,
        segmentUpdate: SegmentUpdateMutation,
        segmentRemove: SegmentRemoveMutation,
        segment: SegmentQuery,
        segmentList: async (vars, meta) => {
            const response = await SegmentListQuery(vars, meta);
            return {
                ...response,
                segmentV2: {
                    segmentList: {
                        // @intent - we use segments to send daily creative emails. this segment
                        // should not be edited through the "classic" segment UI, but only through
                        // onboarding or notification pages
                        data: response.segmentV2.segmentList.data.filter(
                            (s) => s.name !== Segment.secretCreativeEmailSegmentName
                        )
                    }
                }
            };
        },
        creativeEmailSegment: CreativeEmailSegment,
        creativeEmailSegmentCreate: async function (input: CreativeEmailSegmentInput, meta) {
            const response = await SegmentCreateMutation(
                {
                    input: {
                        name: Segment.secretCreativeEmailSegmentName,
                        color: '#7337b6', // irrelevant but its the brand purple
                        emailAlert: true, // enable notification by default
                        definitionV2: {
                            brand: input.brand,
                            category: input.category
                        }
                    }
                },
                meta
            );
            return {
                ...response,
                segmentV2: {creativeEmailSegmentCreate: response.segmentV2.segmentCreate}
            };
        },
        creativeEmailSegmentUpdate: async function (
            input: CreativeEmailSegmentInput & {id: string},
            meta
        ) {
            return CreativeEmailSegmentUpdate(
                {
                    input: {
                        id: input.id,
                        brand: input.brand,
                        category: input.category,
                        emailAlert: input.emailAlert
                    }
                },
                meta
            );
        },
        /** We gotta accept full entities here, because we are not using the API response for
         * normalizing, but instantly returning the input for API */
        followBrand: function* (input: FollowBrandInput, meta) {
            yield {
                segmentV2: {
                    creativeEmailSegmentUpdate: {
                        id: input.id,
                        emailAlert: input.emailAlert,
                        definitionV2: {
                            brand: input.brand,
                            category: input.category
                        }
                    }
                }
            };
            return CreativeEmailSegmentUpdate(
                {
                    input: {
                        id: input.id,
                        brand: input.brand?.map((e) => e.id),
                        category: input.category?.map((e) => e.id),
                        emailAlert: input.emailAlert
                    }
                },
                meta
            );
        }
    }
};

type CreativeEmailSegmentInput = {
    brand?: string[];
    category?: string[];
    emailAlert?: boolean;
};
type FollowBrandInput = {
    id: string;
    brand?: SegmentDefinition['brand'];
    category?: SegmentDefinition['category'];
    emailAlert?: boolean;
};
