import {ErrorBoundary, useLocalStorage, useTheme, usePrevious} from 'bigdatr-style';
import {Grid, Box} from 'bigdatr-style/layout';
import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import AuthenticatedNavigation from './AuthenticatedNavigation';

type Props = {
    children: React.ReactNode;
    hideSidebar: boolean;
    showNavLinks: boolean;
    changeTeam: (teamId: string) => void;
};

const openNavWidth = '16rem';
const closedNavWidth = '3.625rem';
export default function AppLayout(props: Props) {
    const {children, hideSidebar, showNavLinks, changeTeam} = props;
    const {colors} = useTheme();
    const {pathname} = useLocation();
    const [navOpenLocalStorage, setNavOpen] = useLocalStorage('bd-app-sidebarOpen', false);

    const prevPathname = usePrevious(pathname);
    useEffect(() => {
        prevPathname === '/welcome/follow' && setNavOpen(true);
    }, [pathname]);

    if (hideSidebar) return <>{children}</>;

    const navOpen = Boolean(navOpenLocalStorage);
    return (
        <Grid
            height="100vh"
            gridTemplateColumns={{
                md: `${closedNavWidth} auto`,
                xl: `${navOpen ? openNavWidth : closedNavWidth} auto`
            }}
            gridTemplateRows={{_: '4rem auto', md: 'unset'}}
        >
            {/* This surrounding box is needed because we need 2 static children for the grid layout */}
            <Box minWidth={closedNavWidth} data-testid="AppLayout_sidebar">
                <Box
                    boxShadow={{_: navOpen ? '-4px 0 10px 0 #000' : 'none', xl: 'none'}}
                    position={{_: navOpen ? 'fixed' : 'static', md: 'fixed'}}
                    width={{_: '100%', md: navOpen ? openNavWidth : closedNavWidth}}
                    height="100%"
                    top={0}
                    bottom={0}
                    left={0}
                    zIndex="Navigation"
                    background={colors.background}
                >
                    <AuthenticatedNavigation
                        changeTeam={changeTeam}
                        navOpen={navOpen}
                        toggleNav={() => setNavOpen(!navOpen)}
                        showLinks={showNavLinks}
                    />
                </Box>
            </Box>
            <Box height="100%">
                <ErrorBoundary resetKeys={[pathname]}>{children}</ErrorBoundary>
            </Box>
        </Grid>
    );
}
