import {CreativeStatusType} from 'bigdatr-style';
import type {CreativeDisplay} from '~/feature/creative/display-toggle';
import {CreativeListOrderBy} from '~/creative/affordance/CreativeListSortBy';
import {
    ReportItemV2 as GraphqlReportItem,
    FilterColumnV2,
    ReportItemV2UpdateInput,
    ReportItemV2CreateInput,
    MediaValueInputColumn
} from '~/graphqlTypes';

//
//
// Definitions

export const chartTypes = ['Stacked', 'Stacked %', 'Line', 'Matrix', 'Column', 'Ranking'] as const;
export type ChartType = typeof chartTypes[number];
export function isChartType(x: string): x is ChartType {
    return !!chartTypes.find((tt) => x === tt);
}

export type ColumnFilterV2 = {[K in FilterColumnV2]?: Array<string>};

export type MediaDateRangeFilter = {
    truncateMonths?: number;
    absolute?: {startDate: string; endDate: string};
    relative?: {months: number; includeForecasted: boolean};
};

export type MediaValueColumnFilterUpsert = {
    segment?: string[];
    adType?: string[];
    brand?: string[];
    campaign?: string[];
    category?: string[];
    industry?: string[];
    mediaOwner?: string[];
    mediaType?: string[];
    product?: string[];
    publisher?: string[];
    region?: string[];
};

export type MediaValueColumnFilterDefinition = {
    segment?: {name: string; id: string}[];
    adType?: {name: string; id: string}[];
    brand?: {name: string; id: string}[];
    campaign?: {name: string; id: string}[];
    category?: {name: string; id: string}[];
    industry?: {name: string; id: string}[];
    mediaOwner?: {name: string; id: string}[];
    mediaType?: {name: string; id: string}[];
    product?: {name: string; id: string}[];
    publisher?: {name: string; id: string}[];
    region?: {name: string; id: string}[];
};

export type MediaValueDefinition = {
    filter: {
        columns: MediaValueInputColumn[];
        excludedColumns?: MediaValueInputColumn[];
        columnFilter: MediaValueColumnFilterDefinition;
        dateRangeFilter: MediaDateRangeFilter;
    };
    releaseId?: number;
    chartType: ChartType;
};

export type MediaValueDefinitionUpsert = {
    filter: {
        columns: MediaValueInputColumn[];
        excludedColumns?: MediaValueInputColumn[];
        columnFilter: MediaValueColumnFilterUpsert;
        dateRangeFilter: MediaDateRangeFilter;
    };
    releaseId?: number;
    chartType: ChartType;
};

export type CreativeDateRangeFilter = {
    absolute?: {startDate: string; endDate: string};
    relative?: {months: number};
};

/** @deprecated we use `CreativeDateRangeFilter now, but still need it in-app because
 * reports can have old definitions in them*/
export type CreativeDateRange = {
    min: string;
    max: string;
};

export type CreativeColumns =
    | 'adType'
    | 'brand'
    | 'campaign'
    | 'category'
    | 'industry'
    | 'mediaOwner'
    | 'mediaType'
    | 'product'
    | 'publication'
    | 'publisher'
    | 'region'
    | 'segment'
    | 'status';

export type AdvertisingCreativeDefinition = {
    columns: CreativeColumns[];
    filter: {
        dateRangeFilter?: CreativeDateRangeFilter;
        status?: CreativeStatusType[];
        searchQuery?: string;

        // entities
        adType?: {name: string; id: string}[];
        campaign?: {name: string; id: string}[];
        industry?: {name: string; id: string}[];
        category?: {name: string; id: string; industry?: {id: string; name: string}}[];
        mediaOwner?: {name: string; id: string}[];
        mediaType?: {name: string; id: string}[];
        product?: {name: string; id: string}[];
        publication?: {name: string; id: string}[];
        publisher?: {name: string; id: string}[];
        region?: {name: string; id: string}[];
        brand?: {name: string; id: string}[];
        segment?: {name: string; id: string}[];
    };
    chartType: CreativeDisplay;
    orderBy?: CreativeListOrderBy;
};

export type CreativeReportItemUpsertInput = {
    columns: CreativeColumns[];
    filter: {
        dateRangeFilter?: CreativeDateRangeFilter;
        status?: CreativeStatusType[];
        adType?: string[];
        campaign?: string[];
        industry?: string[];
        category?: string[];
        mediaOwner?: string[];
        mediaType?: string[];
        product?: string[];
        publication?: string[];
        publisher?: string[];
        region?: string[];
        brand?: string[];
        segment?: string[];
        searchQuery?: string;
    };
    chartType: CreativeDisplay;
    orderBy?: CreativeListOrderBy;
};

//
//
// Items

export type MediaValueItem = Omit<GraphqlReportItem, 'type' | 'definitionV2'> & {
    type: 'MediaValue';
    definitionV2: MediaValueDefinition;
};

export type AdvertisingCreativeItem = Omit<GraphqlReportItem, 'type' | 'definitionV2'> & {
    type: 'AdvertisingCreative';
    definitionV2: AdvertisingCreativeDefinition;
};

export type ReportItem = MediaValueItem | AdvertisingCreativeItem;
export type ReportItemInput =
    | ReportItemMediaValueCreateInput
    | ReportItemMediaValueUpdateInput
    | ReportItemAdvertisingCreativeCreateInput
    | ReportItemAdvertisingCreativeUpdateInput;

//
//
// Input Types

export type ReportItemMediaValueCreateInput = Omit<ReportItemV2CreateInput, 'definitionV2'> & {
    type: 'MediaValue';
    definitionV2: MediaValueDefinitionUpsert;
};

export type ReportItemMediaValueUpdateInput = Omit<ReportItemV2UpdateInput, 'definitionV2'> & {
    type: 'MediaValue';
    definitionV2: MediaValueDefinitionUpsert;
};

export type ReportItemAdvertisingCreativeCreateInput = Omit<
    ReportItemV2CreateInput,
    'definitionV2'
> & {
    type: 'AdvertisingCreative';
    definitionV2: CreativeReportItemUpsertInput;
};

export type ReportItemAdvertisingCreativeUpdateInput = Omit<
    ReportItemV2UpdateInput,
    'definitionV2'
> & {
    type: 'AdvertisingCreative';
    definitionV2: CreativeReportItemUpsertInput;
};

export type DraftReportItem =
    | ReportItemMediaValueCreateInput
    | ReportItemAdvertisingCreativeCreateInput;

//
// Route Args
export type ReportRouteArgs = {
    id: string;
    draftReportItem?: DraftReportItem;
};
