import {ClassSchema, EntyRequest} from 'bigdatr-style';
import {EntitySchema, ArraySchema, ObjectSchema} from 'react-enty';
import {
    ReportItem,
    ReportMutationReportItemCreateArgs,
    ReportMutationReportItemUpdateArgs,
    ReportMutationReportItemRemoveArgs,
    ReportQueryQueryVariables
} from '~/graphqlTypes';
import Endpoints, {Meta} from '~/app/Endpoints';
import Report from './Report';
const {EntityStrict} = Endpoints;

const ReportQuery = EntityStrict(() => import('./ReportQuery.graphql'));
const ReportListQuery = EntityStrict(() => import('./ReportListQuery.graphql'));
const ReportUpdateMutation = EntityStrict(() => import('./ReportUpdateMutation.graphql'));
const ReportCreateMutation = EntityStrict(() => import('./ReportCreateMutation.graphql'));
const ReportRemoveMutation = EntityStrict(() => import(`./ReportRemoveMutation.graphql`));
const ReportDuplicateMutation = EntityStrict(() => import(`./ReportDuplicateMutation.graphql`));
const ReportItemCreateMutation = EntityStrict(() => import('./ReportItemCreateMutation.graphql'));
const ReportItemRemoveMutation = EntityStrict(() => import('./ReportItemRemoveMutation.graphql'));
const ReportItemUpdateMutation = EntityStrict(() => import('./ReportItemUpdateMutation.graphql'));

const reportItem = new EntitySchema('reportItem');
const reportItemList = new ArraySchema(reportItem);
const report = new EntitySchema('report');
const reportList = new ArraySchema(report);

report.shape = new ClassSchema(Report, {items: reportItemList});

export const ReportSchema = {
    reportV2: new ObjectSchema({
        report,
        reportCreate: report,
        reportUpdate: report,
        reportList,
        reportItemCreate: reportItem,
        reportItemUpdate: reportItem
    })
};

type Request<T> = EntyRequest<{reportV2: T}>;

export type ReportApiType = {
    reportV2: {
        report: Request<{report: Report}>;
        reportCreate: Request<{reportCreate: Report}>;
        reportUpdate: Request<{reportUpdate: Report}>;
        reportRemove: Request<{reportRemove: boolean}>;
        reportDuplicate: Request<{reportDuplicate: Report}>;
        reportList: Request<{reportList: Array<Report>}>;
        reportItemCreate: Request<{report: Report}>;
        reportItemUpdate: Request<{reportItemUpdate: ReportItem}>;
        reportItemRemove: Request<{report: Report}>;
    };
};

export const ReportApi = {
    reportV2: {
        report: ReportQuery,
        reportCreate: ReportCreateMutation,
        reportUpdate: ReportUpdateMutation,
        reportRemove: ReportRemoveMutation,
        reportDuplicate: ReportDuplicateMutation,
        reportList: ReportListQuery,
        reportItemCreate: async ({reportItem, reportId}, meta) => {
            await ReportItemCreateMutation(
                {input: reportItem} as ReportMutationReportItemCreateArgs,
                meta
            );
            return ReportQuery({id: reportId}, meta);
        },
        reportItemRemove: async ({reportItem, reportId}, meta) => {
            await ReportItemRemoveMutation(
                {input: {id: reportItem.id, reportId}} as ReportMutationReportItemRemoveArgs,
                meta
            );
            return ReportQuery({id: reportId} as ReportQueryQueryVariables, meta);
        },
        reportItemUpdate: ({input}: ReportMutationReportItemUpdateArgs, meta: Meta) => {
            return ReportItemUpdateMutation({input}, meta);
        }
    }
};
