import {safeLazyImport, TableSortState} from 'bigdatr-style';
import {createRouterContext, LazyRoute, BoringRoute, Parse} from 'trouty';
import {
    isChartType,
    ReportRouteArgs,
    DraftReportItem
} from '~/report/data/ReportItemDefinitionTypes';
import UserAdminRoutes from '~/user/UserAdminRoutes';
import PdfRoutes from '~/pdf/PdfRoutes';
import type {QueryStringFilters} from '~/feature/filter/types';
import type {CreativeDisplay} from '~/feature/creative/display-toggle';
import {CreativeListOrderBy} from '~/creative/affordance/CreativeListSortBy';
import {CreativeExploreArgs} from '~/creative/CreativeExploreView';
import {MediaValuePageArgs} from '~/pages/explore-media-value/types';

const ReportRoute = safeLazyImport(() => import('~/report/ReportRoute'));
const ReportList = safeLazyImport(() => import('~/report/ReportList'));
const ExportRoute = safeLazyImport(() => import('~/pages/explore-media-value/ExportPage'));
const Dashboard = safeLazyImport(() => import('~/dashboard/Dashboard'));
const StyleguideAffordances = safeLazyImport(() => import('~/styleguide/StyleguideAffordances'));
const StyleguideColors = safeLazyImport(() => import('~/styleguide/StyleguideColors'));
const StyleguideTypography = safeLazyImport(() => import('~/styleguide/StyleguideTypography'));
const SegmentList = safeLazyImport(() => import('~/segment/SegmentList'));
const SegmentItem = safeLazyImport(() => import('~/segment/SegmentItem'));
const DeprecatedActivityEmail = safeLazyImport(() => import('~/pages/DeprecatedActivityEmail'));
const Search = safeLazyImport(() => import('~/search/Search'));
const CreativeExploreView = safeLazyImport(() => import('~/creative/CreativeExploreView'));
const ExploreMediaValuePage = safeLazyImport(
    () => import('~/pages/explore-media-value/ExploreMediaValuePage')
);

const {routes, RoutesProvider, useRoutes} = createRouterContext({
    search: LazyRoute<{q?: string}>({
        path: '/search',
        parse: {
            q: Parse.query.string((x) => x)
        },
        component: Search
    }),
    reportList: BoringRoute({
        path: '/report',
        component: ReportList
    }),
    report: LazyRoute<ReportRouteArgs>({
        path: '/report/:id',
        parse: {
            id: Parse.param.string((x) => x || ''),
            draftReportItem: Parse.state((x) => (x as DraftReportItem) || undefined)
        },
        component: ReportRoute
    }),
    dashboard: BoringRoute({
        path: '/',
        component: Dashboard
    }),
    /** @deprecated use exploreMediaValue */
    dataExport: LazyRoute<{
        filter?: QueryStringFilters;
        chartType?: string;
        columns?: string[];
        excludedColumns?: string[];
    }>({
        path: '/export',
        parse: {
            filter: Parse.query.JSON(
                (x) =>
                    (x ?? {
                        mediaDateRange: {relative: {months: 12, includeForecasted: true}}
                    }) as QueryStringFilters
            ),
            columns: Parse.query.JSON((x) => (Array.isArray(x) ? x : ['brand'])),
            excludedColumns: Parse.query.JSON((x) => (Array.isArray(x) ? x : [])),
            chartType: Parse.query.string((x) => (x && isChartType(x) ? x : 'Matrix'))
        },
        component: ExportRoute
    }),
    exploreMediaValue: LazyRoute<MediaValuePageArgs>({
        path: '/explore-media-value',
        parse: {
            filter: Parse.query.JSON(
                (x) =>
                    (x ?? {
                        mediaDateRange: {relative: {months: 12, includeForecasted: true}}
                    }) as QueryStringFilters
            ),
            columns: Parse.query.JSON((x) => (Array.isArray(x) ? x : ['brand'])),
            excludedColumns: Parse.query.JSON((x) => (Array.isArray(x) ? x : [])),
            chartType: Parse.query.string((x) => (x && isChartType(x) ? x : 'Matrix')),
            releaseId: Parse.query.number((x) => x),
            changesetId: Parse.query.number((x) => x)
        },
        component: ExploreMediaValuePage
    }),
    segmentList: BoringRoute({
        path: '/segment',
        component: SegmentList
    }),
    segment: LazyRoute<{id: string}>({
        path: '/segment/:id',
        parse: {
            id: Parse.param.string((x) => x || '')
        },
        component: SegmentItem
    }),
    /** @deprecated */
    deprecatedActivityEmail: LazyRoute<{id: string}>({
        path: '/inbox/email/:id',
        component: DeprecatedActivityEmail,
        parse: {
            id: Parse.param.string((x) => x || '')
        }
    }),

    exploreCreative: LazyRoute<CreativeExploreArgs>({
        path: '/explore-creative',
        component: CreativeExploreView,
        parse: {
            filter: Parse.query.JSON((x) => (x || {brand: []}) as QueryStringFilters),
            tableSort: Parse.query.JSON((x) => x as TableSortState),
            display: Parse.query.string((q) => q as CreativeDisplay),
            creativeModalId: Parse.query.string((q) => q),
            creativeListOrderBy: Parse.query.JSON((x) => x as CreativeListOrderBy)
        }
    }),

    ...PdfRoutes,
    ...UserAdminRoutes,
    styleguideColors: BoringRoute({
        path: '/styleguide/colors',
        component: StyleguideColors
    }),
    styleguideTypography: BoringRoute({
        path: '/styleguide/typography',
        component: StyleguideTypography
    }),
    styleguideAffordances: BoringRoute({
        path: '/styleguide/affordances',
        component: StyleguideAffordances
    })
});

export {routes, RoutesProvider, useRoutes};
