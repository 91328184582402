import {User} from 'bigdatr-style';
import type {Meta} from '~/app/Endpoints';
import Endpoints from '~/app/Endpoints';
import {UserListQueryQueryVariables} from '~/graphqlTypes';

const {EntityStrict} = Endpoints;
const userUpdate = EntityStrict(() => import('~/user/data/UserUpdateMutation.graphql'));
const userItemQuery = EntityStrict(() => import('~/user/data/UserItemQuery.graphql'));
const userListQuery = EntityStrict(() => import('~/user/data/UserListQuery.graphql'));
const userRevokeAccess = EntityStrict(() => import('~/user/data/UserRevokeAccessMutation.graphql'));
const userAddPrototypeAccess = EntityStrict(
    () => import('~/user/data/UserAddPrototypeAccessMutation.graphql')
);
const userRemovePrototypeAccess = EntityStrict(
    () => import('~/user/data/UserRemovePrototypeAccessMutation.graphql')
);

async function userList(variables: UserListQueryQueryVariables, meta: Meta) {
    const data = await userListQuery(variables, meta);
    if (data.user?.userList) data.user.userList.id = JSON.stringify(variables);
    return data;
}

export default {
    userItem: userItemQuery,
    userList,
    userUpdate: async (user: User, meta: Meta) => {
        const payload = {
            id: user.id,
            firstname: user.firstname,
            lastname: user.lastname,
            phone: user.phone
        };
        return userUpdate({user: payload}, meta);
    },
    userRevokeAccess: async (args: {userId: string}, meta: Meta): Promise<unknown> =>
        userRevokeAccess(args, meta),
    userUpdatePrototypeAccess: async (
        args: {userId: string; hasAccess: boolean},
        meta: Meta
    ): Promise<unknown> => {
        const {userId, hasAccess} = args;
        hasAccess
            ? await userRemovePrototypeAccess({userId}, meta)
            : await userAddPrototypeAccess({userId}, meta);

        // @todo - the user Add and remove functions return an old user.
        // so we can't trust it for normalizing. Just fetch the new copy to get the staet
        return userItemQuery({id: userId}, meta);
    }
};
