import {useCallback, useMemo} from 'react';
import {Brand, Category, Creative, Industry, debounce, useAnalytics} from 'bigdatr-style';
import {MediaValueDefinitionUpsert} from '~/report/data/ReportItemDefinitionTypes';

//
// @intent
// Used to track interactions that cant be determined via page tracking
// must use the schema:
// entity:verb
//
// Good:
//      creative:download, brand:share
//
// Bad:
//      brandProfile:request_creativeItemDownload, brandProfile:change_brand

export default function useEventTracking() {
    const analytics = useAnalytics();

    // we're debouncing search tracking separately, because when customer success is looking at
    // analytics, their reports are hard to follow if the users type really slowly.
    // which means they are seeing events like this:
    // creative:search = c
    // creative:search = ca
    // creative:search = car
    const onCreativeAdContentSearch = useCallback(
        debounce((searchString: string) => {
            analytics.trackEvent('creative:search', {searchString});
        }, 2000),
        []
    );

    const tracking = useMemo(
        () => ({
            // PDF
            pdfDownload: () => analytics.trackEvent('pdf:download'),

            // Creative
            creativeShare: (event: Creative) =>
                analytics.trackEvent('creative:share', event.toTracking()),
            creativeDownload: (event: Creative) =>
                analytics.trackEvent('creative:download', event.toTracking()),
            creativePlaypause: (event: Creative) =>
                analytics.trackEvent('creative:playpause', event.toTracking()),
            creativeMute: (event: Creative) =>
                analytics.trackEvent('creative:mute', event.toTracking()),
            creativeView: (event: Creative) =>
                analytics.trackEvent('creative:view', event.toTracking()),
            creativeFilter: (type: string, value: unknown) =>
                analytics.trackEvent('creative:filter', {type, value}),
            creativeFullscreen: (event: string) =>
                analytics.trackEvent('creative:fullscreen', {type: event}),
            creativeListDownload: (params: {creativeList: string[]; campaignId?: string}) =>
                analytics.trackEvent('creative:downloadList', {
                    creativeIdList: params.creativeList,
                    campaignId: params.campaignId
                }),
            creativeSearch: onCreativeAdContentSearch,

            // Creatives
            creativesDownloadOpen: () => analytics.trackInteraction('creativesDownloadOpen'),

            creativesDownload: (
                payload: {type: 'csv' | 'media'} | {type: 'pdf'; timeTakenMs: number}
            ) => analytics.trackEvent('creatives:download', payload),

            // Media Value
            mediaValueFilter: (payload: {
                location: string;
                startDate?: string;
                endDate?: string;
                industry?: string;
            }) => {
                analytics.trackEvent('mediaValue:filter', payload);
            },
            mediaValueChangeLens: (payload: {
                location: string;
                card: string;
                tab: string;
                chart: string;
            }) => {
                analytics.trackEvent('mediaValue:changeLens', payload);
            },

            // dashboard
            shortcutClick: (payload: Brand | Category | Industry) =>
                analytics.trackEvent('shortcut:click', payload.trackingType),
            shortcutEdit: () => analytics.trackEvent('shortcut:edit'),

            // Charts
            chartDownload: (payload: {
                node: {name: string; type: string};
                lens: Array<string>;
                filter: unknown;
            }) => {
                analytics.trackEvent('chart:download', payload);
            },

            dataTableCsvExport(payload: MediaValueDefinitionUpsert['filter']) {
                analytics.trackEvent('dataTable:csvExport', payload);
            },

            registerCtaClick: (identifier: string) =>
                analytics.trackEvent('registerCTA:click', {identifier})
        }),
        [analytics]
    );
    return tracking;
}
